import { React, useState, useEffect } from 'react';
import { Nav, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Paths } from 'paths';
import "styles/theme.css";
import getBackend from 'backend/backend';

export const Sidebar = (props) => {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        let canceled = false;

        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getProfile();

        return async () => {
            canceled = true;
        }
    }, []);

    return (
            <Row>
                <Col xs={12} sm={6} md={4} lg={3} xl={2} xxl={2} className="border-end bg-light">
                    <Nav className="flex-column">
                        <Nav.Link as={NavLink} to={Paths.NewReport.path} className="header-text" >New Report</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Reports.path} className="header-text" >Reports</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Notifications.path} className="header-text" >Notifications ({profile !== null ? profile.unread_notifications_count : 0})</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Settings.path} className="header-text" >Settings</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Profile.path} className="header-text" >Profile</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Billing.path} className="header-text" >Billing</Nav.Link>
                    </Nav>
                </Col>
                <Col xs={12} sm={6} md={8} lg={9} xl={10} xxl={10} className="min-vh-100">
                    {props.children}
                </Col>
            </Row>
    );
};

export default Sidebar;
