import React from "react";
import { Button } from "react-bootstrap";
import { showMessage, showError } from "components/misc";

const ShareButton = ({ reportLink }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "SiteChecker website analysis report",
          text: "Check out this report!",
          url: reportLink,
        });
        showMessage("Link shared successfully!", "The report URL has been shared with others.");
      } catch (error) {
        showError("Error sharing:", error);
      }
    } else {
      // Fallback: copy to clipboard
      navigator.clipboard.writeText(reportLink).then(() => {
        showMessage("Link copied to clipboard!", "The report URL has been copied to your clipboard and is ready to share.");
      });
    }
  };

  return (
      <Button variant="outline-primary" className="me-2" onClick={handleShare}>
        Share
      </Button>
  );
};

export default ShareButton;